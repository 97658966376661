import Vue from 'vue'
import ColorPicker from './colorpicker.vue'
import $ from 'jquery'
import axios from 'axios'

export function colorpicker (selector) {
  document.querySelectorAll(selector).forEach(item => {
    new Vue({
      components: {ColorPicker},
      render: function (h) {
        return h('ColorPicker', {props: {color: item.value, name: item.name, id: item.id}})
      }
    }).$mount(item)
  })
}

export function ajaxMessageBox (title, url, actions, width, id) {
  let style = ''
  if (width !== null) {
    style = 'style="width: ' + width + '"'
  }

  let customId = 'dialogbox'
  if (id !== null) {
    customId = id
  }

  return new Promise((resolve, reject) => {
    let div = document.createElement('div')

    // Add buttons
    let buttons = ''
    for (let id in actions) {
      if (actions.hasOwnProperty(id)) {
        buttons += `<button id="dialogbox_action_${id}" data-action="${id}" type="button" class="btn btn-default">
  ${actions[id]}
</button>`
      }
    }

    // Get content
    let message = ''
    axios.get(url)
      .then(response => {
        message = response.data

        // Fill modal
        div.innerHTML = `<div class="modal" tabindex="-1" role="dialog" id="` + customId + `">
              <div class="modal-dialog" role="document" ` + style + `>
                <div class="modal-content">
                  <div class="modal-header">
                    <h2 class="modal-title">${title}</h2>
                  </div>
                  <div class="modal-body">` + message + `</div>
                  <div class="modal-footer">
                    ${buttons}
                  </div>
                </div>
              </div>
            </div>`

        document.querySelector('body').appendChild(div)
        let $dialogBox = $('#dialogbox')
        $dialogBox.on('hidden.bs.modal', e => {
          $dialogBox.parent().remove()
        })
        $dialogBox.find('button[data-action]').click(ev => {
          let action = ev.target.getAttribute('data-action')
          if (action === 'confirm') {
            let data = new window.FormData()
            axios.post(url, data).then(response => {
              if (response.status === 200) {
                resolve(ev.target.getAttribute('data-action'))
                $dialogBox.modal('hide')
              }
            }).catch(error => {
              console.error(error)
            })
          } else {
            resolve(ev.target.getAttribute('data-action'))
            $dialogBox.modal('hide')
          }
        })
        $dialogBox.modal('show')
      })
      .catch(function (error) {
        console.log('axios', error)
      })
  })
}
