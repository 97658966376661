import 'default-passive-events'

import $ from 'jquery'
import 'chosen-js'
import { colorpicker } from './widgets'
import 'chosen-js/chosen.css'
import './css/chosen-bootstrap.scss'
import './css/publication.scss'
import Fingerprint2 from 'fingerprintjs2'
import 'timeago'
import axios from 'axios'
import 'jquery-mask-plugin'
import './licenses'
import 'summernote'
import 'summernote/dist/lang/summernote-fr-FR'
import 'summernote/dist/summernote.css'

import 'select2'
import 'select2/dist/css/select2.min.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'

import 'bootstrap-toggle'
import 'bootstrap-toggle/css/bootstrap-toggle.css'

window.isIE = !!document.documentMode // Internet Explorer 6-11
window.isEdge = !window.isIE && !!window.StyleMedia // Edge 20+

// Add select2 widget for all elements select
let selPicker = document.querySelector('.notification-groups')
if (selPicker) {
  $('.notification-groups').select2({
    theme: 'bootstrap',
    width: 'style'
  })
} else {
  // Replace all multiselect by tags input widget
  $('select[multiple]').chosen({
    group_search: false,
    placeholder_text_multiple: ' '
  })
}

// Add chosen widget for all element with class select-chosen
let selChosen = document.querySelector('.select-chosen')
if (selChosen) {
  $('.select-chosen').chosen()
}

// Initialize color picker input widgets
if ($('.clearablefileinput').length) {
  $('.clearablefileinput').filestyle()
}

// Initialize color picker input widgets
if ($('.input-colorpicker').length) {
  colorpicker('.input-colorpicker')
}

// Initialize bootstrap tooltip
if ($('[data-toggle="tooltip"]').length) {
  $('[data-toggle="tooltip"]').tooltip()
}

// Auto hide notification messages
$('.message-alert').fadeTo(30000, 500).slideUp(500, function () {
  $('.message-alert').slideUp(500)
})

let el = document.querySelector('.captcha-refresh')
if (el) {
  $('.captcha-refresh').click(function () {
    let url = $(this).data('ajax-url')
    $.getJSON(url, function (result) {
      $('.captcha').attr('src', result['image_url'])
      $('#id_email-captcha_0').val(result['key'])
    })
  })
}

let expires = new Date()
expires.setFullYear(expires.getFullYear() + 10)
expires = expires.toUTCString()

new Fingerprint2().get(function (result, components) {
  document.cookie = `_hash=${result}; expires=${expires}; path=/`
})

document.cookie = `_tz=${(new Date()).getTimezoneOffset()}; expires=${expires}; path=/`

$('ul.nav.nav-tabs a').click(function (e) {
  e.preventDefault()
  $(this).tab('show')
})

function openTabHash () {
  let url = document.location.toString()
  if (url.match('#')) {
    $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show')
  }
}

openTabHash()
window.addEventListener('hashchange', openTabHash, false)

// Change hash for page-reload
$('.nav-tabs a').on('shown.bs.tab', function (e) {
  if (window.history.pushState) {
    window.history.pushState(null, null, e.target.hash)
  } else {
    window.location.hash = e.target.hash
  }
})

$('time.timeago').timeago()

// Customize defaults headers for ajax requests
let csrftoken = document.querySelector('meta[name="csrf-token"]')
if (csrftoken) {
  csrftoken = csrftoken.getAttribute('content')
}
if (csrftoken) {
  axios.defaults.headers.post['X-CSRFToken'] = csrftoken
  axios.defaults.headers.put['X-CSRFToken'] = csrftoken
  axios.defaults.headers.delete['X-CSRFToken'] = csrftoken
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true

let elEditor = $('.html')
if (elEditor.length > 0) {
  elEditor.summernote({
    dialogsInBody: true,
    disableDragAndDrop: true,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table', 'link', 'picture']],
      ['codeview']
    ],
    height: 300,
    disableResizeEditor: true
  })
}

let elMore = $('.more-link')
let elLess = $('.less-link')

if (elLess.length > 0) {
  elLess.click(function (event) {
    event.preventDefault() /* prevent the a from changing the url */
    $('.more-text').css('display', 'none') /* hide the .more-text span */
    $('.more-link').css('display', 'block')
    $('.less-link').css('display', 'none')
  })
}

if (elMore.length > 0) {
  elMore.click(function (event) {
    event.preventDefault() /* prevent the a from changing the url */
    $('.more-text').css('display', 'block') /* show the .more-text span */
    $('.more-link').css('display', 'none')
    $('.less-link').css('display', 'block')
  })
}

$.fn.datepicker.defaults.format = 'yyyy-mm-dd'
$.fn.datepicker.defaults.autoclose = true
$.fn.datepicker.defaults.orientation = 'bottom'

el = $('.datepicker')
if (el.length > 0) {
  el.datepicker()
  // .mask('9999-99-99', {})
  el.attr('placeholder', 'YYYY-MM-DD')
  el.removeClass('datepicker')
}

function updateAccessLevelInput ($accessType) {
  let $accessLevel = document.getElementById('id_profile-access_level')
  let accessTypes = $accessLevel.getAttribute('data-access-types').split(',')
  $accessLevel.disabled = !accessTypes.includes($accessType)
  $accessLevel.value = $accessLevel.getAttribute('data-default')
}

let $accessType = document.getElementById('id_profile-access_type')
if ($accessType) {
  updateAccessLevelInput($accessType)
  $accessType.addEventListener('change', e => {
    updateAccessLevelInput(e.target.value)
  })
}
