var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "colorpicker",
      staticClass: "input-group input-group-sm color-picker",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.colorValue,
            expression: "colorValue",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "text", id: _vm.id, name: _vm.name },
        domProps: { value: _vm.colorValue },
        on: {
          focus: function ($event) {
            _vm.showPicker()
          },
          input: [
            function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.colorValue = $event.target.value
            },
            _vm.updateFromInput,
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "input-group-addon color-picker-container" },
        [
          _c("span", {
            staticClass: "current-color",
            style: "background-color: " + _vm.colorValue,
            on: {
              click: function ($event) {
                _vm.togglePicker()
              },
            },
          }),
          _vm._v(" "),
          _vm.displayPicker
            ? _c("chrome-picker", {
                attrs: { value: _vm.colors, disableAlpha: true },
                on: { input: _vm.updateFromPicker },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }