import $ from 'jquery'
import axios from 'axios'
import {messageBox} from '../frontend/misc'
import {ajaxMessageBox} from './widgets'
import path from 'path'

$(':file').filestyle('buttonText', '')

function getRootUrl () {
  return document.querySelector('meta[name="root-url"]').getAttribute('content')
}

$('#active-session-list').parent().on('click', '.btn-revoke-session', function (e) {
  let data = new window.FormData()
  data.append('session_key', e.target.getAttribute('data-session'))
  axios.post(path.join(getRootUrl(), '/api/session/revoke'), data).then(response => {
    if (response.status === 200) {
      let $el = document.getElementById('active-session-list').parentNode
      $el.innerHTML = response.data
    }
  }).catch(error => {
    console.error(error.status, error.response)
  })
})

$('.btn-revoke-session-all').on('click', function (e) {
  let data = new window.FormData()
  axios.post(path.join(getRootUrl(), '/api/session/revoke/all'), data).then(response => {
    if (response.status === 200) {
      let $el = document.getElementById('active-session-list').parentNode
      $el.innerHTML = response.data
    }
  }).catch(error => {
    console.error(error)
  })
})

let $epid = document.getElementById('input-add-epid')
let $errorBox = document.getElementById('add-epid-error')
if ($epid) {
  $epid.addEventListener('keypress', e => {
    $errorBox.classList.remove('has-error')
  })
}
$('.btn-activate-license').on('click', function (e) {
  if ($epid.value) {
    let data = new window.FormData()
    data.append('epid', $epid.value)
    axios.post(path.join(getRootUrl(), '/license/activate'), data).then(response => {
      if (response.status === 200) {
        let $el = document.getElementById('active-license-list').parentNode
        $el.innerHTML = response.data
        $epid.value = ''
        $errorBox.classList.remove('has-error')
      }
    }).catch(error => {
      $errorBox.innerHTML = error.response.data
      $errorBox.classList.add('has-error')
    })
  }
  $epid.focus()
})

$('#active-license-list').parent().on('click', '.btn-unregister-user-license', function (e) {
  let data = new window.FormData()
  data.append('epid', e.target.getAttribute('data-epid'))
  axios.post(path.join(getRootUrl(), '/api/user/license/unregister'), data).then(response => {
    if (response.status === 200) {
      let $el = document.getElementById('active-license-list').parentNode
      $el.innerHTML = response.data
    }
  }).catch(error => {
    console.error(error)
  })
})

$('.btn-revoke-own-subscription').on('click', function (e) {
  let title = this.getAttribute('data-title')
  let message = this.getAttribute('data-message')
  messageBox(title, message, {
    'revoke': this.getAttribute('data-action-revoke'),
    'cancel': this.getAttribute('data-action-cancel')
  }).then(action => {
    if (action === 'revoke') {
      let data = new window.FormData()
      data.append('epid', this.getAttribute('data-epid'))
      axios.post(path.join(getRootUrl(), '/api/user/license/unregister'), data).then(response => {
        if (response.status === 200) {
          window.location.reload(true)
        }
      }).catch(error => {
        console.error(error)
      })
    }
  })
})

$('.btn-remove-self-service-user').on('click', function (e) {
  let title = this.getAttribute('data-title')
  let message = this.getAttribute('data-message')
  let url = this.getAttribute('data-url')
  messageBox(title, message, {
    'remove': this.getAttribute('data-action-remove'),
    'cancel': this.getAttribute('data-action-cancel')
  }).then(action => {
    if (action === 'remove') {
      let data = new window.FormData()
      data.append('epid', this.getAttribute('data-epid'))
      data.append('user', this.getAttribute('data-user'))
      axios.post(path.join(getRootUrl(), url), data).then(response => {
        if (response.status === 200) {
          window.location.reload(true)
        }
      }).catch(error => {
        console.error(error)
      })
    }
  })
})

$('.btn-remove-self-service-domain').on('click', function (e) {
  let title = this.getAttribute('data-title')
  let message = this.getAttribute('data-message')
  messageBox(title, message, {
    'remove': this.getAttribute('data-action-remove'),
    'cancel': this.getAttribute('data-action-cancel')
  }).then(action => {
    if (action === 'remove') {
      let data = new window.FormData()
      data.append('epid', this.getAttribute('data-epid'))
      data.append('domain', this.getAttribute('data-domain'))
      axios.post(path.join(getRootUrl(), '/api/license/remove/self-service/domain'), data).then(response => {
        if (response.status === 200) {
          window.location.reload(true)
        }
      }).catch(error => {
        console.error(error)
      })
    }
  })
})

$('.btn-remove-blacklisted-domain').on('click', function (e) {
  let title = this.getAttribute('data-title')
  let message = this.getAttribute('data-message')
  messageBox(title, message, {
    'remove': this.getAttribute('data-action-remove'),
    'cancel': this.getAttribute('data-action-cancel')
  }).then(action => {
    if (action === 'remove') {
      let data = new window.FormData()
      data.append('domain', this.getAttribute('data-domain'))
      axios.post(path.join(getRootUrl(), '/api/license/remove/blacklisted/domain'), data).then(response => {
        if (response.status === 200) {
          window.location.reload(true)
        }
      }).catch(error => {
        console.error(error)
      })
    }
  })
})

$('#id_authorise_users').on('change', function (e) {
  let data = new window.FormData()
  data.append('authorise_users', $('input[name=authorise_users]:checked').val())
  data.append('epid', $('#id_licence').val())
  axios.post(path.join(getRootUrl(), '/api/license/update/self-service/users'), data, {
  }).then(response => {
    if (response.status === 200) {
      // window.location.reload(true)
    }
  }).catch(error => {
    console.error(error)
  })
})

$('#id_authorise_domains').on('change', function (e) {
  let data = new window.FormData()
  data.append('authorise_domains', $('input[name=authorise_domains]:checked').val())
  data.append('epid', $('#id_licence').val())
  axios.post(path.join(getRootUrl(), '/api/license/update/self-service/domains'), data, {
  }).then(response => {
    if (response.status === 200) {
      // window.location.reload(true)
    }
  }).catch(error => {
    console.error(error)
  })
})

function updateAutoRevocation () {
  if (!$('input[name=auto_revocation_module]').is(':checked')) {
    $('.auto-revocation-choices').hide()
    if ((!$('input[name=authorise_users]').is(':checked')) && (!$('input[name=authorise_domains]').is(':checked'))) {
      $('#id-auto-revocation-tooltip').tooltip('enable')
    }
  } else {
    $('.auto-revocation-choices').show()
    $('#id-auto-revocation-tooltip').tooltip('disable')
  }
}

updateAutoRevocation()

$('#id_auto_revocation_module').on('change', function (e) {
  let data = new window.FormData()
  data.append('auto_revocation_module', $('input[name=auto_revocation_module]:checked').val())
  data.append('epid', $('#id_licence').val())
  axios.post(path.join(getRootUrl(), '/api/license/update/auto-revocation-module'), data, {
    headers: {
      'X-CSRFToken': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      'X-Requested-With': 'XMLHttpRequest'
    }
  }).then(response => {
    if (response.status === 200) {
      // window.location.reload(true)
    }
  }).catch(error => {
    console.error(error)
  })
  updateAutoRevocation()
})

$('input[name=auto_revocation]').on('change', function (e) {
  let data = new window.FormData()
  data.append('auto_revocation_choice', $('input[name=auto_revocation]:checked').val())
  data.append('epid', $('#id_licence').val())
  axios.post(path.join(getRootUrl(), '/api/license/update/auto-revocation'), data, {
  }).then(response => {
    if (response.status === 200) {
      // window.location.reload(true)
    }
  }).catch(error => {
    console.error(error)
  })
})

$('#subscriptions-license-list, .subscriptions-license-list').on('click', '.btn-unregister-user-license', function (e) {
  e.preventDefault()
  let title = e.target.getAttribute('data-title')
  let message = e.target.getAttribute('data-message')
  messageBox(title, message, {
    'revoke': e.target.getAttribute('data-action-revoke'),
    'cancel': e.target.getAttribute('data-action-cancel')
  }).then(action => {
    if (action === 'revoke') {
      let data = new window.FormData()
      data.append('epid', e.target.getAttribute('data-epid'))
      data.append('user_id', e.target.getAttribute('data-user'))
      axios.post(path.join(getRootUrl(), '/api/license/unregister/user'), data).then(response => {
        if (response.status === 200) {
          if (response.data.redirect) {
            window.location = response.data.redirect
          } else {
            window.location = window.location.href
          }
        }
      }).catch(error => {
        console.error(error)
        document.getElementById('alert-revoke-error').classList.remove('hidden')
      })
    }
  })
})

let el = $('.set-manager-right')
if (el.length > 0) {
  el.change(function (e) {
    let url = e.target.getAttribute('data-url')
    let title = e.target.getAttribute('data-title')
    ajaxMessageBox(title, url, {
      'confirm': this.getAttribute('data-action-confirm'),
      'cancel': this.getAttribute('data-action-cancel')
    }, null, null).then(action => {
      if (action === 'cancel') {
        this.checked = !this.checked
        e.preventDefault()
        return false
      }
    })
  })
}

$('.btn-cancel-pending-subscription').click(function (e) {
  let url = e.target.getAttribute('data-url')
  let title = e.target.getAttribute('data-title')
  ajaxMessageBox(title, url, {
    'confirm': this.getAttribute('data-action-confirm'),
    'cancel': this.getAttribute('data-action-cancel')
  }, null, null).then(action => {
    if (action === 'cancel') {
      e.preventDefault()
      return false
    }
    if (action === 'confirm') {
      // $(this).closest('tr').remove()
      window.location.reload(true)
    }
  })
})

$('#alert-revoke-error button').on('click', function () {
  document.getElementById('alert-revoke-error').classList.add('hidden')
})

$('input[name=check_available]').on('change', function (e) {
  let data = new window.FormData()
  if ($('.check-available').is(':checked')) {
    $(this).prop('disabled', true)
  }
  let epid = this.getAttribute('data-epid')
  data.append('epid', epid)

  axios.post(path.join(getRootUrl(), '/license/assign'), data, {}).then(response => {
    if (response.status === 200) {
      window.location.reload(true)
    }
  }).catch(error => {
    console.error(error)
    window.location.reload(true)
    window.scrollTo(0, 0)
  })
})

function toggleAssignLicenseForm (single) {
  if (!single) {
    $('.clearablefileinput').filestyle('disabled', false)
    $('.clearablefileinput').filestyle('buttonText', '')
    $('#id_email').prop('disabled', true)
    $('#id_can_manage').prop('disabled', true)
  } else {
    $('.clearablefileinput').filestyle('disabled', true)
    $('.clearablefileinput').filestyle('buttonText', '')
    $('#id_email').prop('disabled', false)
    $('#id_can_manage').prop('disabled', false)
  }
}

function loadErrors (element, error, notification) {
  element.html(error)
  $('.message-alert').remove()
  $('#master').prepend(notification)
  $('.message-alert').fadeTo(30000, 500).slideUp(500, function () {
    $('.message-alert').slideUp(500)
  })
}

el = $('#assign-license-form')
if (el.length > 0) {
  toggleAssignLicenseForm($('input[name=choose_method]:checked').val() === '1')

  $('input[name=choose_method]').on('change', function () {
    toggleAssignLicenseForm($(this).val() === '1')
  })
  $(document).on('click', '#id-assign-submit-btn', function (e) {
    if (($('input[name=choose_method]:checked').val() === '0') && (($('input[name=bulk_file]').val()))) {
      e.preventDefault()
      let loop = setInterval(function () {
        let form = $('#assign-license-form')[0]
        let data = new window.FormData(form)
        let urlAsync = $('#id-assign-submit-btn').attr('data-url')
        axios.post(path.join(getRootUrl(), urlAsync), data, {'Content-Type': 'multipart/form-data'}
        ).then(response => {
          if (response.status === 200) {
            if ((!response.data.status) || ((response.data.status !== 'SUCCESS') && (response.data.status !== 'FAILURE'))) {
              $('#id_users-subscriptions-table').html(response.data.table)
            } else {
              window.location.reload(true)
            }
          }
        }).catch(error => {
          console.error(error)
          clearInterval(loop)
          loadErrors($('#div-assign-license-form'), error.response.data.form, error.response.data.notification)
          toggleAssignLicenseForm($('input[name=choose_method]:checked').val() === '1')
        })
      }, 1000)
    }
  })
}

function toggleAddSelfServiceLicencingForm (single, what) {
  if (!single) {
    $('input[name=bulk_file_' + what + ']').filestyle('disabled', false)
    $('input[name=bulk_file_' + what + ']').filestyle('buttonText', '')
    $('input[name=' + what + ']').prop('disabled', true)
    $('input[name=' + what + ']').val('')
  } else {
    $('input[name=bulk_file_' + what + ']').filestyle('disabled', true)
    $('input[name=bulk_file_' + what + ']').filestyle('buttonText', '')
    $('input[name=bulk_file_' + what + ']').filestyle('clear')
    $('input[name=' + what + ']').prop('disabled', false)
  }
}

el = $('#add-licencing-form')
if (el.length > 0) {
  toggleAddSelfServiceLicencingForm($('input[name=choose_method_users]:checked').val() === '1', 'users')

  $('input[name=choose_method_users]').on('change', function () {
    toggleAddSelfServiceLicencingForm($(this).val() === '1', 'users')
  })

  toggleAddSelfServiceLicencingForm($('input[name=choose_method_domains]:checked').val() === '1', 'domains')

  $('input[name=choose_method_domains]').on('change', function () {
    toggleAddSelfServiceLicencingForm($(this).val() === '1', 'domains')
  })
}

function toggleAuthoriseSelfServiceLicencingForm (what) {
  if (!$('input[name=authorise_' + what + ']').is(':checked')) {
    $('input[name=choose_method_' + what + ']').prop('disabled', true)
    $('input[name=' + what + ']').prop('disabled', true)
    $('input[name=bulk_file_' + what + ']').prop('disabled', true)
    $('input[name=add-submit-btn-' + what + ']').prop('disabled', true)
    $('input[name=' + what + ']').val('')
    $('input[name=bulk_file_' + what + ']').filestyle('clear')
    $('#id_bulk_file_' + what).val('')
    $('#id_bulk_file_' + what).filestyle('disabled', true)
  }
  $('input[name=authorise_' + what + ']').on('change', function () {
    if (!$(this).is(':checked')) {
      $('input[name=choose_method_' + what + ']').prop('disabled', true)
      $('input[name=' + what + ']').prop('disabled', true)
      $('input[name=bulk_file_' + what + ']').prop('disabled', true)
      $('input[name=add-submit-btn-' + what + ']').prop('disabled', true)
      $('#id_bulk_file_' + what).filestyle('disabled', true)
    } else {
      $('input[name=choose_method_' + what + ']').prop('disabled', false)
      $('input[name=add-submit-btn-' + what + ']').prop('disabled', false)
      toggleAddSelfServiceLicencingForm($('input[name=choose_method_' + what + ']:checked').val() === '1', what)
    }
  })
}

el = $('#self-service-licencing')
if (el.length > 0) {
  if (!$('input[name=authorise_users]').is(':checked')) {
    $('#id_choose_method_users_0').prop('checked', true)
    $('#self-service-licencing-list-users').addClass('hidden')
  } else {
    $('#id-auto-revocation-tooltip').tooltip('disable')
  }

  if ((!$('input[name=authorise_users]').is(':checked')) && (!$('input[name=authorise_domains]').is(':checked'))) {
    $('#alert-self-service-link').addClass('hidden')
    $('#self-service-licencing-list-users').addClass('hidden')
    $('#self-service-licencing-list-domains').addClass('hidden')
    if ($('input[name=auto_revocation_module]').is(':checked')) {
      $('input[name=auto_revocation_module]').prop('checked', false).trigger('change')
    }
    $('input[name=auto_revocation_module]').prop('disabled', true)
  }

  $('input[name=authorise_users]').on('change', function () {
    if (!$('input[name=authorise_users]').is(':checked')) {
      if (!$('input[name=authorise_domains]').is(':checked')) {
        $('#alert-self-service-link').addClass('hidden')
        $('input[name=auto_revocation_module]').prop('checked', false).trigger('change')
        $('input[name=auto_revocation_module]').prop('disabled', true)
      } else {
        if ($('#add-licencing-form').attr('data-domains-exist') === 'None') {
          $('#alert-self-service-link').addClass('hidden')
          $('#id-auto-revocation-tooltip').tooltip('enable')
          $('input[name=auto_revocation_module]').prop('checked', false).trigger('change')
          $('input[name=auto_revocation_module]').prop('disabled', true)
        }
      }
      $('#id_choose_method_users_0').prop('checked', true)
      $('#self-service-licencing-list-users').addClass('hidden')
    } else {
      if ($('#add-licencing-form').attr('data-users-exist') !== 'None') {
        $('#alert-self-service-link').removeClass('hidden')
        $('#id-auto-revocation-tooltip').tooltip('disable')
        $('input[name=auto_revocation_module]').prop('disabled', false)
        $('input[name=auto_revocation_module]').parent().removeAttr('disabled')
      }
      $('#self-service-licencing-list-users').removeClass('hidden')
    }
  })

  if (!$('input[name=authorise_domains]').is(':checked')) {
    $('#id_choose_method_domains_0').prop('checked', true)
    $('#self-service-licencing-list-domains').addClass('hidden')
  } else {
    $('#id-auto-revocation-tooltip').tooltip('disable')
  }
  $('input[name=authorise_domains]').on('change', function () {
    if (!$('input[name=authorise_domains]').is(':checked')) {
      if (!$('input[name=authorise_users]').is(':checked')) {
        $('#alert-self-service-link').addClass('hidden')
        $('input[name=auto_revocation_module]').prop('checked', false).trigger('change')
        $('input[name=auto_revocation_module]').prop('disabled', true)
      } else {
        if ($('#add-licencing-form').attr('data-users-exist') === 'None') {
          $('#alert-self-service-link').addClass('hidden')
          $('#id-auto-revocation-tooltip').tooltip('enable')
          $('input[name=auto_revocation_module]').prop('checked', false).trigger('change')
          $('input[name=auto_revocation_module]').prop('disabled', true)
        }
      }
      $('#id_choose_method_domains_0').prop('checked', true)
      $('#self-service-licencing-list-domains').addClass('hidden')
    } else {
      if ($('#add-licencing-form').attr('data-domains-exist') !== 'None') {
        $('#alert-self-service-link').removeClass('hidden')
        $('#id-auto-revocation-tooltip').tooltip('disable')
        $('input[name=auto_revocation_module]').prop('disabled', false)
        $('input[name=auto_revocation_module]').parent().removeAttr('disabled')
      }
      $('#self-service-licencing-list-domains').removeClass('hidden')
    }
  })
  toggleAuthoriseSelfServiceLicencingForm('users')
  toggleAuthoriseSelfServiceLicencingForm('domains')
}

el = $('#copy-activation-key')
if (el.length > 0) {
  el.click(function () {
    let copyText = document.getElementById('input-activation-key')
    copyText.select()
    document.execCommand('copy')
  })
}

function toggleDevice (newDevice) {
  if (newDevice) {
    $('#id_device').attr('disabled', true)
    $('#id_request_key').attr('disabled', false)
    $('#id_name').attr('disabled', false)
  } else {
    $('#id_request_key').attr('disabled', true)
    $('#id_name').attr('disabled', true)
    $('#id_device').attr('disabled', false)
  }
}

el = $('#id_device')
if (el.length > 0) {
  if ($('#id_device option').length === 1) {
    $('#id_choose_method_1').attr('disabled', true)
  }

  toggleDevice($('input[name=choose_method]:checked').val() === '1')

  $('input[name=choose_method]').on('change', function () {
    toggleDevice($(this).val() === '1')
  })
}

function anchorsForSection (sectionId) {
  $(sectionId + '-anchor').find('a').each(function (el) {
    if (typeof $(this).attr('href') !== 'undefined') {
      $(this).attr('href', $(this).attr('href') + sectionId)
    }
  })
}

anchorsForSection('#users-subscriptions')
anchorsForSection('#hw-subscriptions')

if (window.location.hash) {
  let el = $(window.location.hash + '-anchor')
  if (el.length > 0) {
    $('html, body').animate({
      scrollTop: el.offset().top - 189
    }, 0)
  }
}
