import $ from 'jquery'

export function messageBox (title, message, actions) {
  return new Promise((resolve, reject) => {
    let div = document.createElement('div')
    let buttons = ''
    for (let id in actions) {
      if (actions.hasOwnProperty(id)) {
        buttons += `<button id="dialogbox_action_${id}" data-action="${id}" type="button" class="btn btn-default">
  ${actions[id]}
</button>`
      }
    }
    div.innerHTML = `<div class="modal" tabindex="-1" role="dialog" id="dialogbox">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">${title}</h2>
          </div>
          <div class="modal-body">
            <p>${message}</p>
          </div>
          <div class="modal-footer">
            ${buttons}
          </div>
        </div>
      </div>
    </div>`
    document.querySelector('body').appendChild(div)
    let $dialogBox = $('#dialogbox')
    $dialogBox.on('hidden.bs.modal', e => {
      $dialogBox.parent().remove()
    })
    $dialogBox.find('button[data-action]').click(ev => {
      $dialogBox.modal('hide')
      resolve(ev.target.getAttribute('data-action'))
    })
    $dialogBox.modal('show')
  })
}

export function inputBox (title, message, btnLabels = ['OK', 'Cancel'], defaultValue = '', callback = null) {
  return new Promise((resolve, reject) => {
    let div = document.createElement('div')
    div.innerHTML = `<div class="modal" tabindex="-1" role="dialog" id="inputbox">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">${title}</h2>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="input-box-value" class="control-label">${message}</label>
              <input type="text" class="form-control" id="input-box-value" value="${defaultValue}"/>
              <p class="help-block"/p>
            </div>
          </div>  
          <div class="modal-footer">
            <button id="inputbox_action_ok" data-action="ok" type="button" class="btn btn-default" disabled>${btnLabels[0]}</button>
            <button id="inputbox_action_cancel" data-action="cancel" type="button" class="btn btn-default">${btnLabels[1]}</button>
          </div>
        </div>
      </div>
    </div>`
    document.querySelector('body').appendChild(div)
    let $dialogBox = $('#inputbox')

    let $input = document.getElementById('input-box-value')
    let $btnOk = document.getElementById('inputbox_action_ok')
    $input.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        $btnOk.click()
      }
      $btnOk.disabled = $input.value === ''
    })
    $dialogBox.on('shown.bs.modal', e => {
      $input.focus()
    })
    $dialogBox.on('hidden.bs.modal', e => {
      $dialogBox.parent().remove()
    })
    $dialogBox.find('button[data-action]').click(ev => {
      let value = $input.value
      let action = ev.target.getAttribute('data-action')
      if (callback) {
        callback(action, value).then((action, value) => {
          $dialogBox.modal('hide')
          resolve(action, value)
        }).catch(message => {
          document.querySelector('#inputbox p.help-block').textContent = message
          document.querySelector('#inputbox .form-group').classList.add('has-error')
        })
      } else {
        $dialogBox.modal('hide')
        resolve(action, value)
      }
    })
    $dialogBox.modal('show')
  })
}

/**
 * @param  {String} backgroundColor  Background color.
 * @param  {String} lightColor  Use color when color background is dark.
 * @param  {String} darkColor  Use color when color background is light.
 * @return {String}
 */
export function colorFromBackground (backgroundColor, lightColor = '#ffffff', darkColor = '#000000') {
  let color = parseInt(backgroundColor.substr(1), 16)
  if (0.3 * (color >> 16) + 0.59 * (color >> 8 & 0xFF) + 0.11 * (color & 0xFF) <= 128) {
    return lightColor
  } else {
    return darkColor
  }
}

export function isElectron () {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true
  }

  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  return typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0
}

export function truncateString (str, strLgth = 30, custChar = '...') {
  const truncStr = str.substring(0, strLgth)
  return str.length > strLgth ? `${truncStr}${custChar}` : str
}
