
<template>
<div class="input-group input-group-sm color-picker" ref="colorpicker">
  <input type="text" class="form-control" :id="id" :name="name"  v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
	<span class="input-group-addon color-picker-container">
		<span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
    <chrome-picker :value="colors" :disableAlpha="true" @input="updateFromPicker" v-if="displayPicker"></chrome-picker>
	</span>
</div>
</template>

<script>
import Vue from 'vue'
import VueColor from 'vue-color'

export default Vue.extend({
  components: {
    'chrome-picker': VueColor.Chrome
  },
  props: ['color', 'name', 'id'],
  data () {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      displayPicker: false
    }
  },
  mounted () {
    this.setColor(this.color || '#000000')
  },
  methods: {
    setColor (color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors (color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) === 'rgba') {
        let rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        let hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    showPicker () {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker () {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker () {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromInput () {
      this.updateColors(this.colorValue)
    },
    updateFromPicker (color) {
      this.colors = color
      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')'
      }
    },
    documentClick (e) {
      let el = this.$refs.colorpicker
      let target = e.target
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  },
  watch: {
    colorValue (val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
      }
    }
  }
})
</script>

<style>
.vc-chrome {
	position: absolute;
	top: 35px;
	right: 0;
	z-index: 9;
}

.input-group-sm .vc-chrome {
	top: 31px;
}

.current-color {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #000;
	cursor: pointer;
}

.input-group-sm .current-color {
  width: 10px;
	height: 10px;
}

</style>
